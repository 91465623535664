<template>
    <div class="user-message">
        <el-card class="box-card "   shadow="never">
            <div slot="header" class="clearfix">
                <span>消息中心</span>
                <!--                <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button>-->
            </div>
      <!--      <el-tabs v-model="activeType" @tab-click="handleClick" type="border-card">
                <el-tab-pane :label="type.label" :name="type.name" v-for="type in typeList">
                    <el-card class="box-card list-card"  shadow="never">
                        <div slot="header" class="clearfix">
                            <el-tabs v-model="activeName" @tab-click="handleClick">
                                <el-tab-pane label="全部" name="all">

                                </el-tab-pane>
                                <el-tab-pane label="已读" name="read">

                                </el-tab-pane>
                                <el-tab-pane label="未读" name="noread">

                                </el-tab-pane>

                            </el-tabs>
                            &lt;!&ndash;                <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button>&ndash;&gt;
                        </div>
                        <el-table
                                :data="listData.data"
                                border
                                style="width: 100%">
                            <el-table-column

                                    prop="message_title"
                                    label="标题"
                                    width="400">
                            </el-table-column>
                            <el-table-column
                                    align="center"
                                    header-align="center"
                                    prop="status"
                                    label="状态"
                                    width="110">
                                <template slot-scope="scope">
                        <span>
                            {{getStatus(scope.row.status)}}
                        </span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                    align="center"
                                    header-align="center"
                                    prop="createtime"
                                    label="发送时间"
                                    width="170"
                            >
                                <template slot-scope="scope">
                        <span>
                            {{$formatDate(scope.row.createtime, true)}}
                        </span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                    align="center"
                                    header-align="center"
                                    prop=""
                                    label="操作">
                                <template slot-scope="scope">
                                    <el-button
                                            size="mini"
                                            @click="show(scope.row, 'detail')">查看</el-button>
                                    <el-button
                                            size="mini"
                                            @click="show(scope.row, 'edit')">删除</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-card>
                    <el-pagination
                            style="text-align: center;margin-top: 10px"
                            background
                            layout="prev, pager, next"
                            :current-page="listData.current_page"
                            :page-size="Number(listData.per_page)"
                            :page-count="listData.last_page"
                            @current-change="currentChange"
                            :total="listData.total">
                    </el-pagination>
                </el-tab-pane>
            </el-tabs>-->
            <el-card class="box-card list-card"  shadow="never">
                <div slot="header" class="clearfix">
                    <el-tabs v-model="activeName" @tab-click="handleClick">
                        <el-tab-pane label="全部" name="all">

                        </el-tab-pane>
                        <el-tab-pane label="已读" name="read">

                        </el-tab-pane>
                        <el-tab-pane label="未读" name="noread">

                        </el-tab-pane>

                    </el-tabs>
                    <!--                <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button>-->
                </div>
                <el-table
                        :data="listData.data"
                        border
                        style="width: 100%">
                    <el-table-column

                            prop="message_title"
                            label="标题"
                            width="400">
                    </el-table-column>
                    <el-table-column
                            align="center"
                            header-align="center"
                            prop="message_type"
                            label="消息类型"
                            width="110">
                    </el-table-column>
                    <el-table-column
                            align="center"
                            header-align="center"
                            prop="isSeeText"
                            label="状态"
                            width="110">
                    </el-table-column>
                    <el-table-column
                            align="center"
                            header-align="center"
                            prop="createtime"
                            label="发送时间"
                            width="170"
                    >
                        <template slot-scope="scope">
                        <span>
                            {{$formatDate(scope.row.createtime, true)}}
                        </span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            align="center"
                            header-align="center"
                            prop=""
                            width="170"
                            label="操作">
                        <template slot-scope="scope">
                            <el-button
                                    style="margin-right: 5px"
                                    size="mini"
                                    @click="detail(scope.row)">查看</el-button>
                            <el-popconfirm
                                    title="确定删除该消息？"
                                    @confirm="deleteMsg(scope.row)"
                            >
                                <el-button
                                        size="mini"
                                        slot="reference"
                                        >删除</el-button>
                            </el-popconfirm>

                        </template>
                    </el-table-column>
                </el-table>
            </el-card>
            <el-pagination
                    style="text-align: center;margin-top: 10px"
                    background
                    layout="prev, pager, next"
                    :current-page="listData.current_page"
                    :page-size="Number(listData.per_page)"
                    :page-count="listData.last_page"
                    @current-change="currentChange"
                    :total="listData.total">
            </el-pagination>
        </el-card>


        <el-dialog
                title="消息详情"
                :visible.sync="dialogFormVisible"
                v-if="dialogFormVisible"
                width="450px"
                custom-class="msg-dialog"
                :modal="false"
        >
            <el-form>
                <el-form-item label="标题：">
                    <span>{{msgDetail.messagenotice.message_title}}</span>
                </el-form-item>
                <el-form-item label="时间：">
                    <span>{{$formatDate(msgDetail.createtime, true)}}</span>
                </el-form-item>
                <el-form-item label="内容：">
                    <div v-html="msgDetail.messagenotice.message_content">
                    </div>
                </el-form-item>
            </el-form>



<!--            <div slot="footer" class="dialog-footer" >-->
<!--                <el-button @click="dialogFormVisible = false">确定</el-button>-->
<!--            </div>-->
        </el-dialog>
    </div>
</template>

<script>
    import {UserService} from "../../../api/user";

    export default {
        name: "user-order-index",
        data() {
            return {
                activeName: 'all',
                activeType: 'system',
                page: 1,
                sum: 0,
                msgDetail: {},
                dialogFormVisible: false,
                isDetail: false,
                ruleForm: {
                    email: '',
                    name: '',
                    usertype: 'P',
                    taxcode: '',
                    usertitle: '',
                    phone: '',
                    payamount: 0,
                    address: ''
                },
                typeList: [
                    {
                        name: 'system',
                        label: '系统通知'
                    },
                    {
                        name: 'user',
                        label: '站内私信'
                    }
                ],
                formLabelWidth: '100px',
                listData: {
                    current_page: 1,
                    isRecommend: false,
                    last_page: 1,
                    data: [],
                    per_page: 1,
                    total: 0
                },
                rules: {
                    email: [
                        { required: true, message: '请填写邮箱地址', trigger: 'blur' }
                    ],
                    taxcode: [
                        { required: true, message: '请填写税号', trigger: 'blur' }
                    ],
                    usertitle: [
                        { required: true, message: '请填写发票抬头', trigger: 'blur' }
                    ]
                },
            }
        },
        methods: {
            detail(item) {
                // this.$dialog
                UserService.messagedetail({id: item.rec_id}).then(res => {
                    // // console.log('res', res)
                    this.msgDetail = res.data
                    this.dialogFormVisible = true
                })
            },
            deleteMsg(item) {
                UserService.messagedelete({id: item.rec_id}).then(res => {
                    // // console.log('res', res)
                    this.$message.success('操作成功')
                    this.getList()
                })
            },
            summit() {
                this.$refs.ruleForm.validate((valid) => {
                    if (valid) {
                        UserService.setInvoice(this.ruleForm).then(res => {
                            // UserService.setInvoice({params: this.ruleForm}).then(res => {
                            this.dialogFormVisible = false
                            this.$message.success('操作成功')
                            this.page = 1
                            this.getSum()
                            this.getList()
                        })
                    } else {
                        return false;
                    }
                });

            },
            getSum() {
                UserService.invoiceSum().then(res => {
                    // // console.log('res', res)
                    this.sum = res.data.sum
                    if (res.data.last) {
                        Object.keys(this.ruleForm).forEach(key => {
                            this.ruleForm[key] =  res.data.last[key]
                        })
                    }
                })
            },
            handleClick(tab, event) {
                this.listData.data = []
                this.page = 1
                this.getList()
            },
            getStatus(status) {
                return {
                    'normal': '可开票',
                    'invoiced': '已申请',
                    'reject': '拒绝开票',
                    'invoicable': '开票成功'
                }[status]
            },
            currentChange(page) {
                this.page = page
                this.getList()
            },

            getList() {
                const msgType = this.activeType
                const isSee = {
                    'all': -1,
                    'read': 1,
                    'noread': 0,
                }[this.activeName]
                UserService.message({ msgType, isSee, page: this.page }).then(res => {
                    const data = res.data
                    data.data = data.data.map(item => {
                        const newItem = {...item, ...item.messagenotice}
                        newItem.isSeeText = newItem.is_see === 1? '已读' : '未读'
                        newItem.message_type = newItem.message_type === 'user'? '系统私信' : '系统公告'
                        return newItem
                    })
                    this.listData = data
                })
            }
        },
        mounted() {
            this.getSum()
            this.getList()
        }
    }
</script>
<style lang="scss">
    .msg-dialog{

        .el-dialog__body{
            height: 600px;
            overflow: auto;
        }
    }
</style>
<style scoped lang="scss">
    .user-message{

        .content{
            /*height: 200px;*/
            height: 100%;
            .left{
                span{
                    color: $font_1;
                    font-size: 18px;
                }
            }
            .el-col:nth-child(1) {
                /*display: flex;*/
                /*justify-content: center;*/
                /*align-items: center;*/
                height: 100%;
                p:nth-child(1){
                    span{
                        font-size: 12px;
                        color: $font_2;
                    }
                }
                p:nth-child(2){
                    span{
                        font-size: 18px;
                        color: $danger;
                    }
                }
                .el-button{
                    /*margin-left: 20px;*/
                    /*float: right;*/
                }
            }
            .el-col:nth-child(2) {
                width: 1px;
                height: 100%;
                background-color: $border_1;
                margin: 0 20px;

            }
            .el-col:nth-child(3) {
                /*padding: 10px 0;*/
                margin-top: -20px;
                p{
                    font-size: 13px;
                    color: $font_2;
                    /*line-height: 1.6;*/
                    margin: 10px 0;
                }
            }
        }
        .el-tabs--border-card{
            box-shadow: none;
        }
        .list-card{
            height: 520px;
            margin-top: 13px;
        }
    }

</style>